import {inject, Injectable} from '@angular/core';
import {Observable, zip} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {CoreConfig} from '../core.config';
import {Organisation} from '../models/organisation.model';
import {handleHttpError} from '../utilities/error-handling/handle-http-error.pipe$';

@Injectable({
  providedIn: 'root'
})
export class OrganisationResource {
  httpClient = inject(HttpClient);
  coreConfig = inject(CoreConfig);

  public getOrganisations(): Observable<Organisation[]> {
    return this.httpClient.get<{
      success: boolean,
      data: { name: string, id: string }[]
    }>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.portaldatabase}/GetOrgAll?OpenAgent&name=publevel`).pipe(
      handleHttpError(),
      map(response => response.data
        .filter(org => this.isValidOrganisation(org))
        .map(org => this.mapToOrganisationModel(org))
        .map(org => {
          org.publisher = true;
          return org;
        }))
    );
  }

  private mapToOrganisationModel(org: any): Organisation {
    let parentId = null;
    let level = 1;
    if (org.id.length === 6) {
      parentId = org.id.substring(0, 4);
      level = 2;
    }
    if (org.id.length > 6) {
      parentId = org.id.substring(0, 6);
      level = 3;
    }
    return {
      id: org.id,
      name: org.name,
      parentId: parentId,
      level: level,
      publisher: false,
    } as Organisation;
  }

  private isValidOrganisation(org: { name: string, id: string }) {
    const blacklist = ['- Alle -'];
    return  ! blacklist.includes(org.id);
  }
}
