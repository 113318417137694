import {Component, ElementRef, HostListener, inject, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreConfig} from "../../core/core.config";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {CanComponentDeactivate} from "../../core/guards/can-deactivate.guard";
import {DocumentService} from "../../core/services/document/document.service";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-classic-page',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="w-100 h-100">
      <div class="d-flex justify-content-center align-items-center h-100" *ngIf="isLoading">
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Henter data...</span>
        </div>
      </div>
    <iframe class="w-100 h-100 d-none" [src]="url" (load)="onIframeLoad($event)" #classicIframe></iframe>
    </div>
  `,
  styles: [`

  `]
})
export class ClassicComponent implements OnInit, CanComponentDeactivate {
  public coreConfig = inject(CoreConfig);
  public activatedRoute = inject(ActivatedRoute);
  public isLoading = true;
  private sanitizer = inject(DomSanitizer);
  private documentService = inject(DocumentService);
  private router = inject(Router);
  private askForConfirmation = false;
  public url = this.sanitizer.bypassSecurityTrustResourceUrl('') as string;
  @ViewChild('classicIframe') iframe!: ElementRef<HTMLIFrameElement>;

  ngOnInit(): void {
    this.getUrl().subscribe({
      next: (url: string) => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.coreConfig.webBaseUrl + url) as string;
      },
      error: () => this.router.navigate(['documents/error'], {skipLocationChange:true}),
    });

    // handle click on iframe
    const that = this;
    var monitor = setInterval(function () {
      var elem = document.activeElement;
      if (elem && elem.tagName == 'IFRAME') {
        clearInterval(monitor);
        that.askForConfirmation = true;
        //console.log('iframe focused, ask for confirmation');
      }
    }, 100);
  }

  onIframeLoad($event: any): void {
    if (! this.iframe) {
      // still initializing
      return;
    }
    this.isLoading = false;
    this.iframe.nativeElement.classList.remove('d-none');

    // Accessing iframe's content (since it's same-origin)
    const iframeElement = this.iframe.nativeElement;
    const iframeDocument = iframeElement.contentDocument || iframeElement.contentWindow?.document;

    // test if we can access the iframe content, remove once tested
    if (iframeDocument) {
      console.log('Iframe Title:', iframeDocument.title);
      // Perform additional actions as needed
    } else {
      console.warn('Unable to access iframe content.');
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }

  canDeactivate(): boolean {
    if (this.askForConfirmation) {
      return confirm('Ændringer, du har foretaget, gemmes muligvis ikke. Vil du forlade siden?');
    }
    return true;
  }

  private getUrl(): Observable<string> {
    // this assumes that the last part of the url is the page name,
    // defined in the app-routing.module.ts
    const url = this.activatedRoute.snapshot.url.pop()?.path;
    switch (url) {
      case 'my-page':
        return of(this.coreConfig.dominoConfig.mypageurl);
      case 'author':
        return this.getAuthorUrl();
      case 'editor':
        return this.getEditorUrl();
      case 'admin':
        return of(this.coreConfig.dominoConfig.setupurl);
      case 'qreg':
        return of(this.coreConfig.dominoConfig.qregurl);
      case 'review':
        return of(this.coreConfig.dominoConfig.reviewurl);
      case 'search-old':
        return of(this.coreConfig.dominoConfig.searcholdurl);
    }
    return of("");
  }

  private getEditorUrl() {
    const id = this.activatedRoute.snapshot.queryParams.id;
    const db = this.activatedRoute.snapshot.queryParams.db;
    const type = this.activatedRoute.snapshot.queryParams.type;
    const dbdoc = this.activatedRoute.snapshot.queryParams.dbdoc;

    if (!id) {
      // open the editor start page
      return of(this.coreConfig.dominoConfig.editorurl);
    }

    let dbPath: Observable<string>;
    if (!db) {
      dbPath = this.documentService.getDbPath(id);
    } else {
      dbPath = of(db);
    }

    return dbPath.pipe(
      map((dbPath) => {
        if (type === 'attachment') {
          return `/${dbPath}/Main.html?open&unid=${id}&typeid=att`;
        }

        if (type === 'localadd') {
          return `/${dbPath}/Main.html?open&unid=${id}&dbpath=${dbdoc}&typeid=localadd`;
        }

        return `/${dbPath}/Main.html?open&unid=${id}`;
      }));
  }

  private getAuthorUrl() {
    const id = this.activatedRoute.snapshot.queryParams.id;

    if (!id) {
      return of(this.coreConfig.dominoConfig.authorurl);
    }

    return of(`${this.coreConfig.dominoConfig.authorurl}?open&unid=${id}`);
  }


  /**
   * Test urls
   *
   * Forfatter med specifikt ID
   * /legacy/author?id=[ID]
   *
   * Redaktør med doc id:
   * /legacy/editor?id=[ID]
   *
   * Redaktør med doc id og db
   * /legacy/editor?id=[ID]&db=[DB]
   *
   * Redaktør til attachment
   * /legacy/editor?id=[ID]&db=[DB]&type=attachment
   *
   * Redaktør til lokale tilføjelser
   * /legacy/editor?id=[ID]&db=[DB]&type=localadd
   */
}
