import {computed, inject, Injectable, signal} from '@angular/core';
import {SubscriptionResource} from "../resources/subscription-resource";
import {Observable, tap} from "rxjs";
import {
  SubscriptionAttachment,
  SubscriptionCollection,
  SubscriptionDocument,
  SubscriptionList,
  SubscriptionPublisher
} from "../models/document.model";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private subscriptionResource = inject(SubscriptionResource);

  private subscriptions = signal<SubscriptionList>({documents: [], collections: [], publishers: [], attachments: []});
  public attachments = computed(() => this.subscriptions().attachments);
  public documents = computed(() => this.subscriptions().documents);
  public collections = computed(() => this.subscriptions().collections);
  public publishers = computed(() => this.subscriptions().publishers);
  private isInitiated = false;

  public initialLoad(force: boolean = false) {
    if (this.isInitiated && !force) {
      return;
    }
    this.loadSubscriptions();

    this.isInitiated = true;
  }

  public isSubscribed(id: string): boolean {
    // check all types signals  of subscriptions
    return this.attachments().some(attachment => attachment.id === id)
      || this.documents().some(document => document.id === id)
      || this.collections().some(collection => collection.id === id)
      || this.publishers().some(publisher => publisher.id === id);
  }

  toggle(id: string, type: "document" | "collection" | "publisher" | "attachment") {
    this.toggleSubscription(id, type);
  }

  public createSubscriptionPublisher(id: string): Observable<boolean> {
    return this.subscriptionResource.updateSubscription(id, 'publisher', 'create').pipe(
      tap(() => this.loadSubscriptions())
    );
  }

  public loadSubscriptions() {
    this.subscriptionResource.getAllSubscriptions().subscribe(subscriptions => {
      this.subscriptions.set(subscriptions);
    });
  }

  private toggleSubscription(id: string, type: "document" | "publisher" | "collection" | "attachment") {
    let subscription;

    if (this.isSubscribed(id)) {
      this.subscriptions.set({
        documents: this.subscriptions().documents.filter(document => document.id !== id),
        collections: this.subscriptions().collections.filter(collection => collection.id !== id),
        publishers: this.subscriptions().publishers.filter(publisher => publisher.id !== id),
        attachments: this.subscriptions().attachments.filter(attachment => attachment.id !== id),
      });
      subscription = this.subscriptionResource.updateSubscription(id, type, 'remove');
    } else {
      this.subscriptions.set({
        documents: [...this.subscriptions().documents, {id} as SubscriptionDocument],
        collections: [...this.subscriptions().collections, {id} as SubscriptionCollection],
        publishers: [...this.subscriptions().publishers, {id} as SubscriptionPublisher],
        attachments: [...this.subscriptions().attachments, {id} as SubscriptionAttachment],
      });
      subscription = this.subscriptionResource.updateSubscription(id, type, 'create');
    }
    subscription.subscribe(() => this.loadSubscriptions());
  }

}
