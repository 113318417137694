import {computed, Injectable, signal} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {Profile} from '../models/profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileStateService {
  public profile$ = new ReplaySubject<Profile | null>(1);

  private _profileSignal = signal<Profile | null>(null)
  public profile = this._profileSignal.asReadonly();
  public isLoggedIn = computed(() => this._profileSignal() !== null);

  constructor() {
    //this.profile$.next(null)
  }

  public updateProfile(profile: Profile | null) {
    this.profile$.next(profile);
    this._profileSignal.set(profile);
  }
}
