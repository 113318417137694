import {Component, inject, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from "@angular/common";
import {OrganisationNameComponent} from "../components/organisation-name/organisation-name.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CoreConfig} from "../../core/core.config";
import {Router} from "@angular/router";
import {ProfileStateService} from "../../core/states/profile-state.service";
import {ProfileService} from "../../core/services/profile.service";
import {CollectionService} from "../../core/services/collection.service";
import {OrganisationSelectorComponent} from "../components/organication-selector/organisation-selector.component";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {CollectionListItem} from "../../core/models/collection.model";

@Component({
  standalone: true,
  imports: [CommonModule, OrganisationNameComponent, ReactiveFormsModule],
  selector: 'app-user-profile-edit-modal',
  template: `
    <div class="modal-header">
      <h5 class="modal-title">Min profil</h5>
      <button type="button" class="btn-close" (click)="modal.close(false)" aria-label="Luk"></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-3 fw-boldest">Navn</div>
        <div class="col">{{ profileStateService.profile()?.firstname }} {{ profileStateService.profile()?.lastname }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3 fw-boldest">Brugernavn</div>
        <div class="col">{{ profileStateService.profile()?.shortname }}</div>
      </div>

      <div class="row mt-2">
        <div class="col-md-3 fw-boldest">Ansættelsessted</div>
        <div class="col">
          <app-organisation-name [id]="profileStateService.profile()?.level?.employment" [showPath]="true"></app-organisation-name>
        </div>
      </div>

      <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
        <div class="form-group mt-2" *ngIf="coreConfig.dominoConfig.useemail">
          <label for="email">Emailadresse</label>
          <input type="email" class="form-control" id="email" placeholder="" formControlName="email">
        </div>
        <div class="form-group mt-2" *ngIf="coreConfig.dominoConfig.usepincode">
          <label for="pincode">PIN-kode til VIP2Go (4 cifre)</label>
          <input type="text" class="form-control" id="pincode" autocomplete="off" placeholder=""
                 formControlName="pincode">
        </div>
        <div class="form-group mt-2">
          <label>Søgeprofil</label>
          <input type="hidden" autocomplete="off" formControlName="searchLevel" readonly>
          <div class="input-group mb-2">
            <div class="form-control">
              <app-organisation-name [id]="getSearchLevel()" [showPath]="true"></app-organisation-name>&nbsp;
            </div>
            <div class="input-group-text bg-white">
              <button type="button" class="btn btn-xs btn-link" (click)="selectOrganisation()">Vælg afdeling</button>
            </div>
          </div>
        </div>
        <div class="form-group mt-2">
          <label for="startOverview">Startoversigt</label>
          <select class="form-select" id="startOverview" formControlName="startOverview">
            <option *ngFor="let overview of profileService.startOverviews"
                    [value]="overview.value">{{ overview.label }}
            </option>
          </select>
        </div>
        <div class="form-group mt-2">
          <label for="startCollectionLevel">Startniveau-samlinger</label>
          <select class="form-select" id="startCollectionLevel" formControlName="startCollectionLevel">
            <option *ngFor="let overview of profileService.startCollectionLevels"
                    [value]="overview.value">{{ overview.label }}
            </option>
          </select>
        </div>
        <div class="form-group mt-2">
          <label>Start-samling</label>
          <input type="hidden" autocomplete="off" formControlName="startCollection" readonly>
          <div class="input-group mb-2">
            <div class="form-control">
              {{ getStartCollection() }} &nbsp;
            </div>
            <div class="input-group-text bg-white">
              <button type="button" class="btn btn-xs btn-link" (click)="selectCollection()">Vælg samling</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close(false)">Luk</button>
      <button class="btn btn-success" (click)="onSubmit()" [disabled]="profileForm.pristine || profileForm.invalid">
        Gem
      </button>
    </div>
  `,
  styles: [`
    label {
      font-weight: bold;
    }
  `]
})
export class UserProfileEditModalComponent implements OnInit {
  coreConfig = inject(CoreConfig);
  router = inject(Router);
  modalService = inject(NgbModal);
  profileStateService = inject(ProfileStateService);
  profileService = inject(ProfileService);
  collectionService = inject(CollectionService);
  fb = inject(FormBuilder);
  profileForm: FormGroup;// = this.fb.group({});
  modal = inject(NgbActiveModal);

  ngOnInit(): void {
    this.modal.update({size: 'lg'});

    this.profileForm = this.fb.group({
      'searchLevel': [this.profileStateService.profile()?.level.search ?? ''],
      'startOverview': [this.profileStateService.profile()?.startOverview ?? ''],
      'startCollectionLevel': [this.profileStateService.profile()?.startCollectionLevel ?? ''],
      'startCollection': [this.profileStateService.profile()?.startCollectionId ?? ''],
      'startCollectionDisplay': [this.profileStateService.profile()?.startCollectionTitle ?? ''],
    });

    if (this.coreConfig.dominoConfig.useemail) {
      this.profileForm.addControl('email', this.fb.control(this.profileStateService.profile()?.mail, [
        Validators.email
      ]));
    }
    if (this.coreConfig.dominoConfig.usepincode) {
      this.profileForm.addControl('pincode', this.fb.control('', [
        Validators.pattern('^\\d{4}$')
      ]));
    }
  }

  onSubmit() {
    if (this.profileForm.invalid) {
      return;
    }

    const postData = {} as any;

    if (this.coreConfig.dominoConfig.useemail
      && this.profileForm.get('email')?.value !== this.profileStateService.profile()?.mail) {
      postData.email = this.profileForm.get('email')?.value;
    }

    if (this.coreConfig.dominoConfig.usepincode
      && this.profileForm.get('pincode')?.value !== "") {
      postData.pincode = this.profileForm.get('pincode')?.value;
    }

    postData.searchLevel = this.profileForm.get('searchLevel')?.value;
    postData.startOverview = this.profileForm.get('startOverview')?.value
    postData.startCollectionLevel = this.profileForm.get('startCollectionLevel')?.value
    postData.startCollection = this.profileForm.get('startCollection')?.value
    postData.startCollectionDisplay = this.profileForm.get('startCollectionDisplay')?.value

    this.profileService.updateProfile(postData).subscribe(() => {
      this.modal.close(true);
    });

  }

  selectOrganisation() {
    const modal = this.modalService.open(OrganisationSelectorComponent);
    modal.componentInstance.selectedIds = [this.profileForm.get('searchLevel')?.value ?? ''];
    modal.componentInstance.singleSelect = true;
    modal.componentInstance.selectPublishers = false;
    modal.componentInstance.allowEmpty = true;

    from(modal.result).pipe(
      filter((value) => Array.isArray(value)),
    ).subscribe((orgIds: string[]) => {
      this.profileForm.patchValue({
        searchLevel: orgIds[0] ?? '',
      })
      this.profileForm.markAsDirty();
    });
  }

  getSearchLevel() {
    return String(this.profileForm.get('searchLevel')?.value);
  }

  getStartCollection() {
    return String(this.profileForm.get('startCollectionDisplay')?.value);
  }

  selectCollection() {
    let publisherId = 'ALL';
    if (this.profileForm.get('startCollectionLevel')?.value === 'collectionall') {
      publisherId = 'ALL';
    }
    if (this.profileForm.get('startCollectionLevel')?.value === 'collectionniv1') {
      publisherId = 'MAIN';
    }
    if (this.profileForm.get('startCollectionLevel')?.value === 'collectionniv2') {
      publisherId = this.profileStateService.profile()?.level1.default ?? '';
    }
    if (this.profileForm.get('startCollectionLevel')?.value === 'collectionniv3') {
      publisherId = this.profileStateService.profile()?.level.defaultFull ?? '';
    }

    this.collectionService.selectCollection(publisherId).subscribe((collection: CollectionListItem|null) => {
      if (collection === null) {
        this.profileForm.patchValue({
          startCollection: '',
          startCollectionDisplay: '',
        });
      } else {
        this.profileForm.patchValue({
          startCollection: collection.id,
          startCollectionDisplay: collection.title,
        });
      }
      this.profileForm.markAsDirty();
    });
  }
}
