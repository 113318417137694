import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appHighlightInText]',
  standalone: true,
})
export class HighlightInTextDirective implements OnChanges {
  @Input() appHighlightInText: string = '';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges() {
    this.highlightText();
  }

  private highlightText() {
    const text = this.el.nativeElement.textContent;
    if (!this.appHighlightInText) {
      // clear any previous highlighting
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', text);
      return;
    }

    const search = this.appHighlightInText.split(' ').join('|');

    const regex = new RegExp(`(${search})`, 'gi');
    const highlightedText = text.replace(regex, '<span class="fw-bolder">$1</span>');
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', highlightedText);
  }
}
