import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreConfig } from '../core.config';
import { DominoResponse, DominoUserProfile, DominoUserRolesResponse } from '../models/domino-response.model';
import { Profile } from '../models/profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileResource {
  constructor(private http: HttpClient, private coreConfig: CoreConfig) {}

  getUserProfile(): Observable<DominoUserProfile | null> {
    return this.http.get<DominoResponse<DominoUserProfile>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.menudatabase}/GetUserProfile?OpenAgent`, {
      withCredentials: true
    }).pipe(
      map(result => result.data),
      map(dominoUserProfile => dominoUserProfile.username === "" ? null : dominoUserProfile)
    );
  }
  updateUserProfile(postData: Partial<{
    email: string,
    pincode: string,
    searchLevel: string,
    startOverview: string,
    startCollectionLevel: string,
    startCollection: string,
    startCollectionDisplay: string,
  }>): Observable<Profile>  {
    let formData = new HttpParams();

    if (postData.email) {
      formData = formData.set('mailaddress', postData.email);
    }
    if (postData.pincode) {
      formData = formData.set('pincode', postData.pincode);
    }
    const searchLevel = String(postData?.searchLevel);
    formData = formData.set('level1search', searchLevel.substring(0, 4));
    formData = formData.set('level2search', searchLevel.substring(4, 6));
    formData = formData.set('level3search', searchLevel.substring(6, 8));
    formData = formData.set('activetabenduser', String(postData.startOverview));
    formData = formData.set('activetabcollection', String(postData.startCollectionLevel));
    formData = formData.set('defaultcollection', String(postData.startCollection));
    formData = formData.set('defaultcollectiondisplay', String(postData.startCollectionDisplay));

    return this.http.post<DominoResponse<Profile>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.adminDatabase}/updateUserProfile?OpenAgent`,
      formData.toString(), {
        withCredentials: true
      }).pipe(
      map(result => result.data)
    );
  }
}
