import {Component, ElementRef, inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {
  IActionMapping,
  ITreeOptions, KEYS,
  TreeComponent,
  TreeModel,
  TreeModule,
  TreeNode
} from "@ali-hm/angular-tree-component";
import {tap, timer, zip} from "rxjs";
import {ITreeNode} from "@ali-hm/angular-tree-component/lib/defs/api";
import {CollectionService} from "../../../core/services/collection.service";
import {CollectionListItem} from "../../../core/models/document.model";
import {CoreModule} from "../../../core/core.module";
import {CollectionTreeViewComponent} from "../../../modules/collections/components/collection-tree-view.component";
import {FormsModule} from "@angular/forms";
import {HighlightInTextDirective} from "../../../core/directives/highlight-in-text.directive";
import {TreeBuilderUtil} from "../../../core/utilities/tree-builder.util";
import {OrganisationService} from "../../../core/services/organisation/organisation.service";
import {AppUtils} from "../../../core/utilities/app-utils";

@Component({
  selector: 'app-collection-selector',
  standalone: true,
  imports: [CommonModule, TreeModule, CoreModule, CollectionTreeViewComponent, FormsModule, HighlightInTextDirective],
  template: `
    <div class="modal-header">
      <h5 class="modal-title">
        Vælg en samling
      </h5>
      <button type="button" class="btn-close" (click)="modal.close()" aria-label="Luk"></button>
    </div>
    <div class="modal-body">
      <form class="search-form">
        <div class="form-row align-items-center">
          <div class="col">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text bg-white"><i class="icon-search  text-success"></i>
                </div>
              </div>
              <input type="search"
                     name="search"
                     #searchInput
                     autocomplete="one-time-code"
                     (input)="filterCollections(searchInput.value)"
                     class="form-control form-control-lg shadow-none pe-0" id="inlineFormInputGroup"
                     [placeholder]="getSearchBoxPlaceholder()">
              <div class="input-group-text bg-white pe-0 d-none d-lg-block">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="filterSwitchCollectionSelector"
                         name="titleOnlyFilter"
                         (click)="setSearchInputFocus(); filterCollections(searchInput.value)"
                         [(ngModel)]="titleOnlyFilter">
                  <label class="form-check-label" for="filterSwitchCollectionSelector">Filtrér i titel</label>
                </div>
              </div>
              <div class="input-group-append">
                <div class="input-group-text bg-white h-100"></div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="tree-view-scroll">
        <tree-root #tree
                   [nodes]="collectionTreeNodes"
                   [options]="options"
                   [focused]="true"
                   [ngClass]="'tree'">
          <ng-template #treeNodeTemplate let-node let-index="index">
            <div class="tree-node-content">
              <div class="row gx-0 m-0">
                <div class="col-auto p-0">
                  <i class="text-success me-1 fs-4"
                     [class.icon-binder]=" ! node.data.isOrganisation"
                     [class.icon-opened-folder]="node.data.isOrganisation && node.isExpanded"
                     [class.icon-folder]="node.data.isOrganisation && ! node.isExpanded"
                  ></i>
                </div>
                <div class="col mt-1">
                    <span *ngIf="node.data.isOrganisation" [appHighlightInText]="titleOnlyFilter ? '': searchInput.value"  [innerHTML]="node.data.name"></span>
                    <span *ngIf="! node.data.isOrganisation" [appHighlightInText]="titleOnlyFilter ? searchInput.value: ''" [innerHTML]="node.data.name"></span>
                </div>
              </div>
            </div>
          </ng-template>
        </tree-root>
      </div>
    </div>
    <div class="modal-footer">
      <div class="float-start me-auto">
        Valgt samling: {{ getSingleSelectedTitle() }}
      </div>
      <button type="button" class="float-end btn btn-outline-secondary" (click)="closeWithNoCollection()"
              *ngIf="allowEmpty">Intet valg
      </button>
      <button type="button" class="float-end btn btn-secondary" (click)="closeWithChosen()"
              [disabled]=" ! chosenCollection">Vælg
      </button>
    </div>
  `,
  styles: [`
    .modal-body {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    }

    .tree-view-scroll {
      overflow-y: auto;
      height: calc(100vh - 330px);
    }

    @media (min-width: 992px) {
      .tree-view-scroll {
        height: calc(100vh - 400px);
      }
    }
  `,
  ]
})
export class CollectionSelectorComponent implements OnInit {
  private collectionService = inject(CollectionService);
  private treeBuilderUtil = inject(TreeBuilderUtil);
  private organisationService = inject(OrganisationService);
  public modal = inject(NgbActiveModal);
  public appUtils = inject(AppUtils);
  public chosenCollection: CollectionListItem | null = null;
  public collectionTreeNodes: Partial<ITreeNode>[];
  public filterPublisherId = '';
  public allowEmpty = false;
  public titleOnlyFilter = false;
  @ViewChild('tree') tree!: TreeComponent;
  @ViewChild('searchInput') searchInput: ElementRef;

  ngOnInit(): void {
    this.modal.update({size: 'lg'});
    zip([
      this.collectionService.getCollections(this.filterPublisherId),
      this.organisationService.publishers$,
    ]).pipe(
      tap(([collections, organisations]) => this.collectionTreeNodes = this.treeBuilderUtil.buildTree(collections, organisations, this.filterPublisherId)),
    ).subscribe();
  }

  options: ITreeOptions = {
    allowDrag: (node: any) => false,
    allowDrop: (node: any) => false,
    allowDragoverStyling: false,
    levelPadding: 10,
    useVirtualScroll: true,
    animateExpand: true,
    idField: 'id',
    displayField: 'title',
    childrenField: 'children',
    isExpandedField: 'isExpanded',
    scrollOnActivate: true,
    useCheckbox: false,
    animateSpeed: 30,
    animateAcceleration: 1.2,
    useTriState: false,
    scrollContainer: document.documentElement, // HTML
    actionMapping: {
      mouse: {
        click: (tree: TreeModel, node: ITreeNode, $event: any) => {
          this.handleSelection(node);
        },
        dblClick: (tree: TreeModel, node: ITreeNode, $event: any) => {
          if (node.hasChildren) {
            node.toggleExpanded();
            return;
          }
          this.handleSelection(node);
          this.closeWithChosen();
        }
      },
      keys: {
        [KEYS.ENTER]: (tree: TreeModel, node: ITreeNode, $event: any) => {
          this.handleSelection(node);
        }
      }
    } as IActionMapping,
  };

  setSearchInputFocus() {
    // only focus on desktop ot avoid keyboard popping up on mobile
    if ( ! this.appUtils.isMobile()) {
      this.searchInput.nativeElement.focus();
    }
  }

  private handleSelection(node: ITreeNode) {
    if (node.hasChildren) {
      node.toggleExpanded();
      return;
    }

    node.toggleActivated(false);

    if (node.isActive) {
      this.chosenCollection = node.data.collection;
      return;
    }

    this.chosenCollection = null;
  }

  getSingleSelectedTitle() {
    return this.chosenCollection?.title ?? '';
  }

  closeWithChosen() {
    return this.modal.close(this.chosenCollection);
  }

  closeWithNoCollection() {
    return this.modal.close(null);
  }

  public filterCollections(searchTerm: string) {
    let autoExpandNodes = true;
    setTimeout(() => {
      this.tree.treeModel.filterNodes((node: TreeNode) => {
        // split search string into words and search for each word in publisher and titles
        const searchTerms: string[] = this.searchInput.nativeElement.value.split(' ');
        let haystack = node.data.name.toLowerCase();

        if (! this.titleOnlyFilter && !node.data.isOrganisation) {
          // if we are only showing organisations, we need to search in the organisation name
          haystack = node.data.organisationName.toLowerCase();
        }

        if (this.titleOnlyFilter && node.data.isOrganisation) {
          // if we are not only showing collections, no need to search in organisation names
          return false;
        }

        return searchTerms.every(word => {
          return haystack.includes(word);
        });
      }, autoExpandNodes);

    }, 0);
  }

  getSearchBoxPlaceholder() {
    return this.titleOnlyFilter ? 'Filtrér i titel' : 'Filtrér i udgiver';
  }
}
