import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Data, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {MenuService} from "./menu.service";
import {of, zip} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private titlePostFix = '';
  private routeTitle = '';
  private routeAdditionalTitle = '';
  private menuTitle = '';

  constructor(
    private ngTitleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService,
  ) {
  }

  public init(postFix = ""): void {
    this.titlePostFix = postFix;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      mergeMap(route => route.data),
      switchMap(data => zip(
        this.menuService.getMenuItemTitleByUrl(this.router.url),
        of(data),
      )),
    ).subscribe(([menuTitle, pageData]: [string | null, Data]) => {
      this.routeTitle = pageData.title;
      this.routeAdditionalTitle = pageData.additionalTitel ?? '';
      this.menuTitle = menuTitle ?? '';
      this.updateWindowTitle();
    });
  }

  public updateWindowTitle(): void {
    if (this.menuTitle && this.routeAdditionalTitle) {
      this.ngTitleService.setTitle(`${this.menuTitle}: ${this.routeAdditionalTitle} ${this.titlePostFix}`);
      return;
    }

    if (this.menuTitle) {
      this.ngTitleService.setTitle(`${this.menuTitle} ${this.titlePostFix}`);
      return;
    }

    if (this.routeTitle) {
      this.ngTitleService.setTitle(`${this.routeTitle} ${this.titlePostFix}`);
    }
  }

  public updateTitle(documentTitle: string): void {
    this.ngTitleService.setTitle(`${documentTitle} ${this.titlePostFix}`);
  }

  public setFullTitle(title: string): void {
    this.ngTitleService.setTitle(title);
  }

  public setRouteAdditionalTitle(additionalTitle: string): void {
    this.routeAdditionalTitle = additionalTitle;
    this.updateWindowTitle();
  }

  public prependRouteTitle(prepend: string): void {
    this.ngTitleService.setTitle(`${prepend} - ${this.routeTitle} ${this.titlePostFix}`);
  }
}
