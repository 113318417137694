import {inject, Injectable} from '@angular/core';
import {ProfileStateService} from "../states/profile-state.service";
import {CoreConfig} from "../core.config";
import {first, Observable, tap} from "rxjs";
import {Profile, ProfileLevel} from "../models/profile.model";
import {filter, map} from "rxjs/operators";
import {ProfileResource} from "../resources/profile.resource";
import {DominoUserProfile} from "../models/domino-response.model";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profileResource = inject(ProfileResource);
  profileStateService = inject(ProfileStateService);
  coreConfig = inject(CoreConfig);

  public startOverviews = [
    {value: '', label: 'Systemstandard'},
    {value: 'search', label: 'Dokumenter'},
    {value: 'collection', label: 'Samlinger'},
    {value: 'favorites', label: 'Favoritter'},
    {value: 'subscription', label: 'Abonnementer'},
    {value: 'my-page', label: 'Min side'},
    {value: 'editor', label: 'Redaktør'},
  ];
  public startCollectionLevels = [
    {value: '', label: 'Systemstandard'},
    {value: 'collectionall', label: 'Alle samlinger'},
    {value: 'collectionniv1', label: 'Regionale samlinger'},
    {value: 'collectionniv2', label: 'Min enheds samlinger'},
    {value: 'collectionniv3', label: 'Min afdelings samlinger'},
  ];

  updateLoginStatus(forceUpdate = false): Observable<boolean> {
    return this.getProfile().pipe(
      tap(userProfile => {
        const isLoggedIn = !!userProfile;
        if (isLoggedIn !== this.profileStateService.isLoggedIn()) {
          this.profileStateService.updateProfile(userProfile)
        } else if (forceUpdate) {
          this.profileStateService.updateProfile(userProfile)
        }
      }),
      map(userProfile => !!userProfile),
    );
  }

  /**
   * @deprecated use getProfile instead
   */
  private getProfileAndUpdateState(): Observable<Profile | null> {
    return this.profileResource.getUserProfile().pipe(
      map((dominoUserProfile) => {
        if (!dominoUserProfile) {
          // user not logged in
          return null;
        }
        return this.mapToProfile(dominoUserProfile);
      }),
      tap(userProfile => this.profileStateService.updateProfile(userProfile)),
    );
  }

  private getProfile(): Observable<Profile | null> {
    return this.profileResource.getUserProfile().pipe(
      map((dominoUserProfile) => {
        if (!dominoUserProfile) {
          // user not logged in
          return null;
        }
        return this.mapToProfile(dominoUserProfile);
      }),
    );
  }

  refreshProfile() {
    return this.getProfile().pipe(
      tap(userProfile => this.profileStateService.updateProfile(userProfile)),
    );
  }


  updateProfile(postData: Partial<{
    email: string,
    pincode: string,
    searchLevel: string,
    startOverview: string,
    startCollectionLevel: string,
    startCollection: string,
    startCollectionDisplay: string,
  }>) {
    return this.profileResource.updateUserProfile(postData).pipe(
      tap(userProfile => this.refreshProfile().subscribe())
    );
  }

  private mapToProfile(dominoUserProfile: DominoUserProfile): Profile {
    const profile: Partial<Profile> = {
      firstname: dominoUserProfile.firstname,
      lastname: dominoUserProfile.lastname,
      mail: dominoUserProfile.mailaddress,
      username: dominoUserProfile.username,
      shortname: dominoUserProfile.shortname,
      startOverview: dominoUserProfile.activetabenduser,
      startCollectionLevel: dominoUserProfile.activetabcollection,
      startCollectionId: dominoUserProfile.defaultcollection,
      startCollectionTitle: dominoUserProfile.defaultcollectiondisplay,
      roles: dominoUserProfile.userroles.map(role => role.toLowerCase()),
    }

    const hasSearchLevel = dominoUserProfile.level1search;
    const levelDefault1: string = hasSearchLevel ? dominoUserProfile.level1search : dominoUserProfile.level1;
    const levelDefault2: string = hasSearchLevel ? dominoUserProfile.level2search : dominoUserProfile.level2;
    const levelDefault3: string = hasSearchLevel ? dominoUserProfile.level3search : dominoUserProfile.level3;
    const levelDefault: string = levelDefault1 + levelDefault2 + levelDefault3;

    profile.level = {
      default: levelDefault,
      defaultFull: levelDefault,
      search: dominoUserProfile.level1search + dominoUserProfile.level2search + dominoUserProfile.level3search,
      employment: dominoUserProfile.level1 + dominoUserProfile.level2 + dominoUserProfile.level3,
    }

    profile.level1 = {
      default: levelDefault1,
      defaultFull: levelDefault1,
      search: dominoUserProfile.level1search,
      employment: dominoUserProfile.level1,
    }
    profile.level2 = {
      default: levelDefault2,
      defaultFull: levelDefault1 + levelDefault2,
      search: dominoUserProfile.level3search,
      employment: dominoUserProfile.level2,
    }

    profile.level3 = {
      default: levelDefault3,
      defaultFull: levelDefault1 + levelDefault2 + levelDefault3,
      search: dominoUserProfile.level3search,
      employment: dominoUserProfile.level3
    };
    return profile as Profile;
  }


  getStartPageRedirectUrl() {
    return this.profileStateService.profile$.pipe(
      first(),
      map(profile => {
        if (!profile) {
          return this.getStartpageSystemDefault();
        }
        return this.getRedirectUrlForAuthenticatedUsers(profile);
      })
    );
  }

  private getRedirectUrlForAuthenticatedUsers(profile: Profile): string {
    if (profile.startOverview === 'search') {
      return '/documents';
    }
    if (profile.startOverview === 'favorites') {
      return '/favorites';
    }
    if (profile.startOverview === 'subscription') {
      return '/subscriptions';
    }

    if (profile.startOverview === 'my-page') {
      return '/legacy/my-page';
    }

    if (profile.startOverview === 'editor') {
      return '/legacy/editor';
    }

    if (profile.startOverview === 'collection') {
      if (profile.startCollectionId) {
        return '/collections/' + profile.startCollectionId;
      }

      if (profile.startCollectionLevel === 'collectionall') {
        return '/collections?publisher=ALL';
      }
      if (profile.startCollectionLevel === 'collectionniv1') {
        return '/collections?publisher=MAIN';
      }
      if (profile.startCollectionLevel === 'collectionniv2') {
        return '/collections?publisher=' + profile.level1.default;
      }
      if (profile.startCollectionLevel === 'collectionniv3') {
        return '/collections?publisher=' + profile.level2.defaultFull;
      }
      return '/collections';
    }

    return this.getStartpageSystemDefault();
  };

  private getStartpageSystemDefault() {
    return '/documents';
  }

  public getDefaultCollectionView(): Observable<string> {
    return this.profileStateService.profile$.pipe(
      first(),
      map(profile => {
        let publisher;
        let defaultView = this.getDefaultCollectionSystemView(profile);
        switch (defaultView) {
          case '1':
            publisher = 'ALL';
            break;
          case '2':
            publisher = 'MAIN';
            break;
          case '3':
            publisher = profile?.level1.default;
            break;
          case '4':
            publisher = profile?.level2.default ? profile?.level2.defaultFull : profile?.level1.default;
        }
        return publisher || 'ALL';
      }),
    );
  }

  private getDefaultCollectionSystemView(profile: Profile | null): string {
    if (!profile) {
      return this.coreConfig.dominoConfig.collectiondefaultanonymous;
    }
    const mapping: { [key: string]: string } = {
      'collectionall': '1',
      'collectionniv1': '2',
      'collectionniv2': '3',
      'collectionniv3': '4',
    };
    return mapping[profile.startCollectionLevel] || this.coreConfig.dominoConfig.collectiondefaultlogon;
  }
}
