import {inject, Injectable} from '@angular/core';
import {MenuItem} from "../../views/layout/sidebar/menu.model";
import {CoreConfig} from "../core.config";
import {Profile} from "../models/profile.model";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {DominoArrayResponse, DominoMenuItem, DominoMenuItemChildren} from "../models/domino-response.model";
import {Observable, ReplaySubject, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private coreConfig = inject(CoreConfig);
  private http = inject(HttpClient);
  private menuItems$ = new ReplaySubject<DominoMenuItem[]>(1);

  loadMenuItems() {
    return this.http.get<DominoArrayResponse<DominoMenuItem>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.menudatabase}/GetMenu?OpenAgent`).pipe(
      tap(result => this.menuItems$.next(result.data)),
      map(() => true),
    );
  }

  getMenuItemTitleByUrl(url: string): Observable<string | null> {
    return this.menuItems$.pipe(
      map((items) => {
        const flattenedItems = this.flattenDominoMenuItems(items);
        //find the most matching item
        let foundItem = flattenedItems.find(item => item.url === url)
          ?? flattenedItems.find(item => item.url && url.startsWith(item.url))
          ?? undefined;

        return foundItem ? foundItem.text : null;
      })
    );
  }

  private flattenDominoMenuItems(items: DominoMenuItem[]): DominoMenuItem[] {
    return items.reduce((acc, item) => {
      acc.push(item);
      if (item.children) {
        acc.push(...this.flattenDominoMenuItems(item.children));
      }
      return acc;
    }, [] as DominoMenuItem[]);
  }

  getDynamicMenuItemsForProfile(profile: Profile | null): Observable<MenuItem[]> {
    const profileRoles = profile?.roles || ['anonymous'];
    return this.menuItems$.pipe(
      map((result) => this.mapToMenuItems(result, profileRoles)),
    );
  }

  private mapToMenuItems(items: DominoMenuItem[], profileRoles: string[]): MenuItem[] {
    return items
      .filter(item => this.anyRoleMatch(profileRoles, this.getRolesForMenuItem(item)))
      .map(item => {
        const menuItem = {
          label: item.text,
          iconClass: item.badgeurl,
          link: item.url ?? '',
          openInNewWindow: item.openInNewWindow ?? false,
          isExternalLink: String(item.url).startsWith('http'),
        } as MenuItem;
        menuItem.subItems = item.children ? this.mapChildrenToMenuItems(item.children, profileRoles) : [];
        return menuItem;
      });
  }

  private mapChildrenToMenuItems(children: DominoMenuItemChildren[], profileRoles: string[]): MenuItem[] {
    return children.filter(item => this.anyRoleMatch(profileRoles, this.getRolesForMenuItem(item)))
      .map(item => {
        return {
          label: item.text,
          iconClass: item.badgeurl,
          link: item.url,
          openInNewWindow: item.openInNewWindow ?? false,
          isExternalLink: String(item.url).startsWith('http'),
        } as MenuItem;
      });
  }

  private anyRoleMatch(profileRoles: string[], menuItemRoles: string[]) {
    profileRoles = profileRoles.map(role => role.toLowerCase());
    menuItemRoles = menuItemRoles.map(role => role.toLowerCase());

    return menuItemRoles.some(role => profileRoles.includes(role));
  }

  private getRolesForMenuItem(item: DominoMenuItem | DominoMenuItemChildren): string[] {
    // if  item.access.roles is a string, convert it to an array. item.access can also be empty
    if (Array.isArray(item?.access?.roles)) {
      return item?.access?.roles as string[];
    }

    if (typeof item?.access?.roles === 'string') {
      return [item?.access?.roles] as string[];
    }

    return [];
  }
}

