import {inject, Injectable} from '@angular/core';
import {catchError, Observable, of, switchMap, tap, throwError, zip} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {CoreConfig} from '../core.config';
import {
  Document,
  DocumentComment,
  DocumentPrivateNote,
  DocumentSection,
  LocalEditLevel
} from '../models/document.model';
import {handleHttpError} from '../utilities/error-handling/handle-http-error.pipe$';
import {map} from "rxjs/operators";
import {
  DominoArrayResponse, DominoComment,
  DominoDocument,
  DominoDocumentLocationResponse,
  DominoLocalEditLevels,
  DominoResponse
} from "../models/domino-response.model";
import {ProfileStateService} from "../states/profile-state.service";

@Injectable({
  providedIn: 'root'
})
export class DocumentResource {
  httpClient = inject(HttpClient);
  coreConfig = inject(CoreConfig);
  profileStateService = inject(ProfileStateService);

  private getDocumentLocation(id: string) {
    return this.httpClient.get<DominoDocumentLocationResponse>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.containerDatabase}/GetDocumentById?OpenAgent&id=${id}`, {
      withCredentials: true
    }).pipe(
      map(response => {
        if (!response.success) {
          throw new HttpErrorResponse({status: 404, statusText: 'Dokument ikke fundet'});
        }
        return response;
      }),
    );
  }

  public getDocumentById(id: string, level: string): Observable<Document> {
    return this.getDocumentLocation(id).pipe(
      switchMap((response) => this.loadDocument(id, level, response.dbfilepath)),
    );
  }

  public getArchivedDocument(documentID: string, dbPath: string, localAdditionOrganisationId: string) {
    if (dbPath.startsWith('/')) {
      dbPath = dbPath.substring(1);
    }
    return this.loadDocument(documentID, localAdditionOrganisationId, dbPath, true);
  }

  private loadDocument(id: string, level: string, dbfilepath: string, archived = false): Observable<Document> {
    return zip(
      this.getDocumentFromLocation(id, level, dbfilepath, archived),
      this.getLocalEditLevels(id, dbfilepath),
      this.getValidForOrganisations(id, dbfilepath),
      of(dbfilepath),
    ).pipe(
      map(([document, localEditLevels, validForOrganisations, dbFilePath]:
             [DominoDocument, DominoLocalEditLevels[], {name: string}[], string]) =>
        this.mapToDocument(document, localEditLevels, validForOrganisations, dbFilePath)),
    );
  }

  public getLocalEditLevels(id: string, dbfilepath: string): Observable<DominoLocalEditLevels[]> {
    return this.httpClient.get<DominoArrayResponse<DominoLocalEditLevels>>(`${this.coreConfig.api.baseUrl}/${dbfilepath}/GetLocalAdditionsForAllUnits?OpenAgent&docid=${id}`, {
      withCredentials: true
    }).pipe(
      map((response) => response.data ?? []),
      catchError(err => of([])), // ignore 404 errors in case local edits do not exist.
    );
  }

  private getDocumentFromLocation(id: string, level: string, dbfilepath: string, archived = false): Observable<DominoDocument> {
    return this.httpClient.get<DominoResponse<DominoDocument>>(`${this.coreConfig.api.baseUrl}/${dbfilepath}/aLoadInfoDokRead?OpenAgent&id=${id}&level=${level}&showarchive=${archived ? 1 : 0}`, {
      withCredentials: true
    }).pipe(
      map(response => {
        if (!response.success) {
          throw new HttpErrorResponse({status: 404, statusText: 'Dokument ikke fundet'});
        }
        return response.data
      })
    )
  }

  private getValidForOrganisations(id: string, dbfilepath: string): Observable<{name: string}[]> {
    const data = "docid=" + id;

    return this.httpClient.post<DominoResponse<{
      name: string
    }[]>>(`${this.coreConfig.api.baseUrl}/${dbfilepath}/GetValidForValues?OpenAgent`, data, {
      withCredentials: true,
    }).pipe(
      map((response) => response.data ?? []),
      catchError(err => of([])), // ignore 404 errors in case getValidForValues does not exist.
    );
  }

  public getHelpDocument() {
    return this.httpClient.get(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.adminDatabase}${this.coreConfig.dominoConfig.webhelpurl}`, {
      withCredentials: true,
      responseType: 'text',
    }).pipe(
      map(response => this.createDocumentFromHTML('Hjælp', response)),
    );
  }

  public getOperationInformation() {
    return this.httpClient.get(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.adminDatabase}${this.coreConfig.dominoConfig.operationinfopurl}`, {
      withCredentials: true,
      responseType: 'text',
    }).pipe(
      map(response => this.createDocumentFromHTML('Driftsinformation', response)),
    );
  }


  public sendReadReceipt(id: string): Observable<boolean> {
    return this.getDocumentById(id, '').pipe(
      switchMap((document) => {
        if (!document.isPendingReadReceipt) {
          return of(false);
        }

        let data = new HttpParams();
        data = data.set('id', document.id);
        data = data.set('version', document.version);
        data = data.set('user', "" + this.profileStateService.profile()?.username);


        return this.httpClient.post<DominoResponse<void>>(`${this.coreConfig.api.baseUrl}/${document.dbFilePath}/setReadReceipt?OpenAgent`,
          data.toString(), {
            withCredentials: true,
          }).pipe(
          map(response => response.success),
        )
      })
    )
  }

  public getComments(id: string, dbfilepath: string): Observable<DocumentComment[]> {
    const data = "docid=" + id;

    return this.httpClient.post<DominoArrayResponse<DominoComment>>(`${this.coreConfig.api.baseUrl}/${dbfilepath}/GetComments?OpenAgent`,
      data, {
      withCredentials: true
    }).pipe(
      map(response => {
        if (!response.success) {
          throw new HttpErrorResponse({status: 404, statusText: 'Kommentarer ikke fundet'});
        }
        return response.data;
      }),
      map(comments => this.mapComments(comments)),
    )
  }

  public addComment(id: string, dbFilePath: string, comment: string): Observable<boolean> {
    let data = new HttpParams();
    data = data.set('id', id);
    data = data.set('user', "" + this.profileStateService.profile()?.username);
    data = data.set('comment', comment);

    return this.httpClient.post<DominoResponse<void>>(`${this.coreConfig.api.baseUrl}/${dbFilePath}/AddComment?OpenAgent`,
      data.toString(), {
        withCredentials: true,
      }).pipe(
      map(response => {
        if (!response.success) {
          throw new HttpErrorResponse({status: 500, statusText: 'Det skete en fejl ved tilføjelse af kommentar'});
        }
        return response.success;
      }),
    );
  }

  savePrivateNote(id: string, section: DocumentSection, content: string) {
    let data = new HttpParams();
    data = data.set('action', 'save');
    data = data.set('docid', id);
    data = data.set('sectionid', section.id);
    data = data.set('sectionname', section.title);
    data = data.set('text', content);

    return this.httpClient.post<DominoResponse<void>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.adminDatabase}/PrivateNotesPost?OpenAgent`,
      data, {
        withCredentials: true,
      }).pipe(
      map(response => {
        if (!response.success) {
          throw new HttpErrorResponse({status: 500, statusText: 'Det skete en fejl ved gem af mine noter'});
        }
        return response.success;
      }),
    );
  }

  private mapToDocument(document: DominoDocument, localEditLevels: DominoLocalEditLevels[], validForOrganisations: {
    name: string
  }[], dbPath: string): Document {
    return {
      id: document.id,
      title: document.Afsnit,
      version: document.Versionsnr,
      html: document.BodyWeb,
      sections: document.Sections.map(section => {
        return {
          id: section.sectionid,
          title: section.sectionname,
        } as DocumentSection;
      }),
      localEdits: this.mapLocalEdits(document),
      localEditLevels: this.mapToLocalEditLevels(localEditLevels),
      validForOrganisations: validForOrganisations,
      professionalResponsible: document.VisForfatter,
      authors: this.splitMultipleValues(document.ForfatterListe),
      editors: this.splitMultipleValues(document.EditorList),
      documentType: document.PlejeLaegelig,
      publisher: document.PublisherDisplay,
      approver1: document.Godkender1,
      approver2: document.Godkender2,
      approver3: document.GodkenderEkstra,
      auditResponsible: document.RevisionSendTil,
      auditNext: document.RevisionsDato,
      approveManagement: document.ApproveManagement,
      profession: this.splitMultipleValues(document.Profession),
      targetGroup: this.splitMultipleValues(document.TargetGroup),
      effectiveFrom: document.StartDato,
      qualityStandards: this.splitMultipleValues(document.EksternReference),
      changeLogHistoric: document.ReleaseLog,
      changeLog: document.SenesteRettelse,
      isFavorite: document.ShowFavorite === '2',
      keywords: this.splitMultipleValues(document.Emneord),
      isSubscribed: document.ShowSubscription === '0',
      description: document.Description,
      privateNotes: this.mapPrivateNotes(document),
      pendingApprovalMessage: document.DocIsNotAcceptedMsg,
      isPendingReadReceipt: document.ShowReadReceipt === '1',
      hasComments: document.ShowComment === '1',
      dbFilePath: dbPath,
      documentNumber: document.DocNumber,
      levelCode: document.LevelCode, // local edits additions level
      status: document.Status,
      pdfLandScape: document.PDFLandScape === 'X',
      isPublished: document.Publiceres === 'Ja',
      approvalLevel: document.approvallevel,
      url: './documents/' + document.id + ( document.Publiceres !== 'Ja' ? '?requirelogin=1' : ''),
    } as Document
  }

  private mapLocalEdits(document: DominoDocument) {
    if (document.LocalEditID === '') {
      return [];
    }
    const numberOfLocalEdits = document.LocalEditID.split('¤').length;
    const localEdits = [];

    for (let i = 0; i < numberOfLocalEdits; i++) {
      const localEdit = {
        id: document.LocalEditID.split('¤')[i],
        content: document.LocalEditText.split('¤')[i],
        name: document.LocalEditName.split('¤')[i],
        style: document.LocalEditStyle.split('¤')[i],
        level: document.LocalEditLevel.split('¤')[i],
      };
      localEdits.push(localEdit);
    }
    return localEdits;
  }

  private mapToLocalEditLevels(localEditLevels: DominoLocalEditLevels[]) {
    return localEditLevels.map((item) => {
      return {name: item.name, level: item.level} as LocalEditLevel
    })
  }


  private splitMultipleValues(content: string) {
    return content.split('¤').filter(value => value !== '');
  }

  private mapComments(comments: DominoComment[]): DocumentComment[] {
    return comments.map(comment => {
      return {
        id: comment.id,
        comment: comment.comment,
        createdAt: comment.created,
        createdByName: comment.createdby,
        createdByUsername: comment.createduser,
      } as DocumentComment;
    });
  }

  private mapPrivateNotes(document: DominoDocument): DocumentPrivateNote[] {
    return document.PrivateNotes.map(note => {
      return {
        sectionId: note.sectionid,
        sectionTitle: note.sectionname,
        content: note.text.replace(/<br>/g, '\n'),
        updatedAt: note.modified,
      } as DocumentPrivateNote
    });
  }


  private fakeLocalEdits(document: Document) {
    document.localEdits = [
      ...document.localEdits,
      {
        id: 'tester',
        content: "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A adipisci asperiores molestias nesciunt non nulla omnis perspiciatis porro provident quia ratione sapiente similique, tempora voluptas, voluptate. Blanditiis sequi tempore ullam?</p>\n",
        name: 'Form&aring;l',
        style: 'locallevel1',
        level: 'Amager Hospitaler&nbsp;&nbsp;&nbsp;&nbsp; Godkender: Bent Abildskov Nielsen',
      },
      {
        id: '1.1 Hvem vurderer behov for tolk',
        content: "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus alias consequuntur</p>\n",
        name: 'tster',
        style: 'locallevel2',
        level: 'Bispebjerg Hospital > Anæstesiologisk Overafdeling&nbsp;&nbsp;&nbsp;&nbsp; Godkender: Bent Abildskov Nielsen',
      }
    ];
    return document;
  }


  private createDocumentFromHTML(title: string, html: string) {
    const doc = {
      html: html,
    } as Document;
    doc.localEditLevels = [];
    doc.localEdits = [];
    doc.privateNotes = [];
    doc.title = title;

    return doc;
  }

  getDbPath(id: string) {
    return this.getDocumentLocation(id).pipe(
      map(response => response.dbfilepath),
    );
  }


}
