import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CoreConfig} from '../core.config';
import {map} from "rxjs/operators";
import {DominoResponse, DominoSubscription} from "../models/domino-response.model";
import {Observable} from "rxjs";
import {
  SubscriptionAttachment,
  SubscriptionCollection,
  SubscriptionDocument,
  SubscriptionList,
  SubscriptionPublisher
} from "../models/document.model";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionResource {
  public httpClient = inject(HttpClient);
  public coreConfig = inject(CoreConfig);


  getAllSubscriptions(): Observable<SubscriptionList> {
    return this.httpClient.get<DominoResponse<DominoSubscription[]>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.subscriptionDatabase}/GetSubscriptions?OpenAgent&action=all`, {
      withCredentials: true
    }).pipe(
      map(response => response.data),
      map(data => this.mapSubscriptions(data)),
    )
  }

  updateSubscription(id: string, type: string, action: string) {
    return this.httpClient.get<DominoResponse<any>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.subscriptionDatabase}/updateUserSubscriptions?OpenAgent&doctype=${type}&action=${action}&id=${id}`, {
      withCredentials: true
    }).pipe(
      map(response => {
        if (!response.success) {
          throw new Error('API Error: ' + (response as any)?.msg);
        }
        return response.success;
      }),
    )
  }

  private mapSubscriptions(data: DominoSubscription[]): SubscriptionList {
    return {
      documents: data
        .filter(subscription => subscription.doctype === 'document')
        .map(subscription => this.mapSubscriptionDocument(subscription)),
      collections: data
        .filter(subscription => subscription.doctype === 'collection')
        .map(subscription => this.mapSubscriptionCollection(subscription)),
      publishers: data
        .filter(subscription => subscription.doctype === 'publisher')
        .map(subscription => this.mapSubscriptionPublisher(subscription)),
      attachments: data
        .filter(subscription => subscription.doctype === 'attachment')
        .map(subscription => this.mapSubscriptionAttachment(subscription)),
    } as SubscriptionList;
  }

  private determineLevel(publisherId: string) {
    return publisherId.length === 4 ? 1 : publisherId.length === 6 ? 2 : publisherId.length === 8 ? 3 : 0;
  }

  private mapSubscriptionDocument(subscription: DominoSubscription): SubscriptionDocument {
    return {
      id: subscription.docid,
      title: subscription.title,
      level: this.determineLevel(subscription.publisherid),
      publisher: subscription.publisherdisplay,
      publisherId: subscription.publisherid,
      type: subscription.plejelaegelig
    } as SubscriptionDocument;
  }

  private mapSubscriptionCollection(subscription: DominoSubscription): SubscriptionCollection {
    return {
      id: subscription.docid,
      title: subscription.title,
      level: this.determineLevel(subscription.publisherid),
      publisher: subscription.publisherdisplay,
      publisherId: subscription.publisherid,
    } as SubscriptionCollection;
  }

  private mapSubscriptionPublisher(subscription: DominoSubscription): SubscriptionPublisher {
    return {
      level: this.determineLevel(subscription.publisherid),
      publisher: subscription.publisherdisplay,
      id: subscription.publisherid,
    } as SubscriptionPublisher;
  }

  private mapSubscriptionAttachment(subscription: DominoSubscription): SubscriptionAttachment {
    return {
      id: subscription.docid,
      title: subscription.title,
      level: this.determineLevel(subscription.publevelid),
      publisher: subscription.publisherdisplay,
      publisherId: subscription.publisherid,
      link: this.coreConfig.api.baseUrl
        + "/"
        + this.coreConfig.dominoConfig.mediadatabase
        + "/vUpload/"
        + subscription.docid,
    } as SubscriptionAttachment;
  }
}
