<nav class="sidebar">
  <div class="sidebar-header">

    <!--- Logo -->
    <a routerLink="/" class="sidebar-brand">
      <img src="{{ coreConfig.webBaseUrl + '/' + coreConfig.dominoConfig.uploaddatabase + coreConfig.dominoConfig.whitelabel.logotop }}" alt="">
    </a>

    <!--- Toggler -->
    <div class="sidebar-toggler not-active" #sidebarToggler (click)="toggleSidebar($event)">
      <span></span>
      <span></span>
      <span></span>
    </div>

  </div>
  <div class="sidebar-body" (mouseenter)="operSidebarFolded()" (mouseleave)="closeSidebarFolded()">

    <!--- Sidemenu start -->

    <ul class="sidebar-nav metismenu" id="sidebar-menu" #sidebarMenu>
      <ng-container *ngFor="let item of menuItems">
        <li class="nav-item nav-category" *ngIf="item.isTitle">{{ item.label }}</li>

        <li class="nav-item" [class.mm-active]="item.isOpen" *ngIf="!item.isTitle">

          <ng-container *ngIf="!hasItems(item)">
            <a class="nav-link nav-link-ref"
               *ngIf="!item.isExternalLink"
               [routerLink]="item.link"
               [class.mm-active]="item.isActive"
               [target]="item.openInNewWindow ? '_blank' : '_self'">
              <i [class]="item.iconClass" class="link-icon text-brand"></i>
              <span class="link-title text-brand"> {{ item.label }}</span>
              <span class="badge bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text}}</span>
            </a>

            <a class="nav-link nav-link-ref"
               *ngIf="item.isExternalLink"
               [href]="item.link"
               [target]="item.openInNewWindow ? '_blank' : '_self'">
              <i [class]="item.iconClass" class="link-icon text-brand"></i>
              <span class="link-title text-brand wd-100p"> {{ item.label }}</span>
              <span class="badge bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text}}</span>
            </a>
          </ng-container>

          <ng-container *ngIf="hasItems(item)">
            <a class="nav-link"
               (click)="item.isOpen = ! item.isOpen"
               href="javascript:void(0);">
              <i [class]="item.iconClass" class="link-icon text-brand"></i>
              <span class="link-title text-brand"> {{ item.label }}</span>
              <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
            </a>

            <ul class="sidebar-nav sub-menu nav-second-level" aria-expanded="false"
                [class.open]="item.isOpen">
              <li class="nav-item" *ngFor="let subitem of item.subItems">

                <a class="nav-link nav-link-ref"
                   *ngIf="!subitem.isExternalLink"
                   [routerLink]="subitem.link"
                   [class.mm-active]="subitem.isActive"
                   [target]="subitem.openInNewWindow ? '_blank' : '_self'">
                  <i [class]="subitem.iconClass" class="link-icon text-brand"></i>
                  <span class="link-title text-brand"> {{ subitem.label }}</span>
                  <span class="badge bg-{{subitem.badge.variant}}" *ngIf="subitem.badge">{{subitem.badge.text}}</span>
                </a>

                <a class="nav-link nav-link-ref"
                   *ngIf="subitem.isExternalLink"
                   [href]="subitem.link"
                   [target]="subitem.openInNewWindow ? '_blank' : '_self'">
                  <i [class]="subitem.iconClass" class="link-icon text-brand"></i>
                  <span class="link-title text-brand wd-100p"> {{ subitem.label }}</span>
                  <span class="badge bg-{{subitem.badge.variant}}" *ngIf="subitem.badge">{{subitem.badge.text}}</span>
                </a>

              </li>
            </ul>
          </ng-container>

        </li>
      </ng-container>
    </ul>

    <!--- Sidemenu end -->

  </div>
</nav>
