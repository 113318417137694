import {CollectionListItem} from "../models/document.model";
import {Organisation} from "../models/organisation.model";
import {ITreeNode} from "@ali-hm/angular-tree-component/lib/defs/api";
import {inject, Injectable} from "@angular/core";
import {OrganisationService} from "../services/organisation/organisation.service";


@Injectable({
  providedIn: 'root',
})
export class TreeBuilderUtil {
  organisationService = inject(OrganisationService);

  public buildTree(collectionListItems: CollectionListItem[], organisations: Organisation[], organisationId: string): Partial<ITreeNode>[] {
    let dataTree: Partial<ITreeNode>[] = [];
    if (organisationId === 'ALL') {
      dataTree = this.generateTreeNodesForAll(organisations, collectionListItems);
    } else {
      // build list of collections for selected publisher
      dataTree = this.generateTreeNodesForSinglePublisher(organisationId, collectionListItems);
    }

    // sort tree
    dataTree.sort((a: any, b: any) => a.name.localeCompare(b.name));
    dataTree = dataTree.map((node: any) => {
      return {...node, name: node.name.trim()};
    });
    dataTree.forEach((node: any) => node.children.sort((a: any, b: any) => {
      if (a.isOrganisation && b.isOrganisation) {
        return a.name.localeCompare(b.name);
      }
      return a.sort.localeCompare(b.sort);
    }));

    return dataTree as Partial<ITreeNode>[];
  }

  private generateTreeNodesForAll(organisations: Organisation[], collectionListItems: CollectionListItem[]) {
    let shownOrganisations: Organisation[] = organisations
      .filter(org => collectionListItems.some(item => item.publisherId === org.id));

    const hashTable = Object.create(null);
    shownOrganisations.forEach(org => {
      const parentOrg = org.parentId ? this.organisationService.getOrganisationById(org.parentId) : null;
      let name = org.name;
      if (parentOrg) {
        name = parentOrg.name + ' &rarr; ' + name;
      }
      hashTable[org.id] = {
        id: org.id,
        name: name,
        organisationName: name,
        collectionId: null,
        isOrganisation: true,
        children: []
      }
    });

    // add organisation collections
    const dataTree: Partial<ITreeNode>[] = [];

    shownOrganisations.forEach(organisation => {
      collectionListItems.forEach(item => {
        if (item.publisherId === organisation.id) {
          hashTable[organisation.id].children.push({
            id: item.id,
            name: item.title,
            sort: item.sort,
            organisationName: hashTable[organisation.id].organisationName,
            collectionId: item.id,
            children: [],
            collection: item,
            isOrganisation: false,
          });
        }
      });

      dataTree.push(hashTable[organisation.id])
    });
    return dataTree;
  }

  private generateTreeNodesForSinglePublisher(publisherId: string, collectionListItems: CollectionListItem[]) {
    let filteredCollections = collectionListItems;
    let organisationName = '';
    if (publisherId === 'MAIN') {
      publisherId = '0000';
      filteredCollections = filteredCollections.filter(item => item.publisherId === publisherId);
      organisationName = this.organisationService.getOrganisationById(publisherId)?.name ?? '';
    } else {
      filteredCollections = filteredCollections.filter(item => item.publisherId === publisherId);
      organisationName = this.organisationService.getOrganisationById(publisherId)?.name ?? '';
    }

    let dataTree: Partial<ITreeNode>[] = [];
    dataTree.push(...filteredCollections.map(item => {
      return {
        id: item.id,
        name: item.title,
        isOrganisation: false,
        organisationName: organisationName,
        collectionId: item.id,
        collection: item,
        children: [],
      };
    }));
    return dataTree;
  }
}
