// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appId: 'edok',

  webBaseUrl: '',


  api: {
    baseUrl: '',
    appConfigUrl: 'edok/admin/admin.nsf/GetFrontendConfig?OpenAgent',
  },

  featureFlags: {
    documentPropertiesLayout: 'edok',
  },

  auth: {
    keycloakUrl: '',
    keycloakRealm: '',
    keycloakLogoutUrl: '',
    clientId: '',
    clientSecret: '',
    baseUrl: '',

    defaultLogin: {
      email: '',
      password: ''
    }
  },
};

import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
