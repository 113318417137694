import {Component, forwardRef, inject, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrganisationService} from "../../../core/services/organisation/organisation.service";

@Component({
  selector: 'app-organisation-name',
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-container *ngIf="id">
      <ng-container
        *ngIf="showPath && organisationService.getOrganisationById(id)?.parentId as parentId">
        <ng-container
          *ngIf="organisationService.getOrganisationById(parentId)?.parentId as parentParentId">
          {{ organisationService.getOrganisationById(parentParentId)?.name }} &rarr;
        </ng-container>
        {{ organisationService.getOrganisationById(parentId)?.name }} &rarr;
      </ng-container>
      {{ organisationService.getOrganisationById(id)?.name ?? '&nbsp;' }}
    </ng-container>
  `,
  styles: [`
    :host {
      display: inline;
    }
  `]
})
export class OrganisationNameComponent {
  organisationService = inject(forwardRef(() => OrganisationService));
  @Input() id: string | undefined;
  @Input() showPath: boolean = false;
}
