import {Component, ElementRef, Inject, inject, Input, OnInit, ViewChild} from '@angular/core';
import {DOCUMENT} from '@angular/common';

import {MenuItem} from './menu.model';
import {NavigationEnd, Router} from '@angular/router';
import {ProfileStateService} from "../../../core/states/profile-state.service";
import {CoreConfig} from "../../../core/core.config";
import {Subject} from "rxjs";
import {MenuService} from "../../../core/services/menu.service";
import {AppUtils} from "../../../core/utilities/app-utils";
import {switchMap} from "rxjs/operators";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public coreConfig = inject(CoreConfig);
  public appUtils = inject(AppUtils);
  private menuService = inject(MenuService);
  private router = inject(Router);
  private profileStateService = inject(ProfileStateService);

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;
  @Input() sidebarToggled!: Subject<void>;
  menuItems: MenuItem[] = [];

  ngOnInit(): void {
    this.menuService.loadMenuItems().subscribe();

    this.profileStateService.profile$.pipe(
      switchMap(profile => this.menuService.getDynamicMenuItemsForProfile(profile)),
    ).subscribe((menuItems) => {
      this.menuItems = this.updateMenuState(menuItems, this.router.url);
    });

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this.menuItems = this.updateMenuState(this.menuItems, event.url);

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }

      }
    });

    this.sidebarToggled.subscribe(() => {
      this.toggleSidebar(new Event('click'));
    });

    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
    desktopMedium.addEventListener('change', () => {
      this.iconSidebar;
    });
    this.iconSidebar(desktopMedium);
  }

  private updateMenuState(items: MenuItem[], url: string): MenuItem[] {
    let highestPrecisionScore = 0;

    this.flattenMenuItems(items).forEach(item => {
      highestPrecisionScore = Math.max(highestPrecisionScore, this.getActivePrecisionScore(item.link, url));
    });

    if (highestPrecisionScore === 0) {
      highestPrecisionScore = -1; // handle 404 pages
    }

    return items.map(item => {
      item.isActive = this.getActivePrecisionScore(item.link, url) === highestPrecisionScore;
      item.subItems.forEach(subItem =>
        subItem.isActive = this.getActivePrecisionScore(subItem.link, url) === highestPrecisionScore);

      const setIsOpen = item.subItems.some((subItem: MenuItem) => subItem.isActive);
      if (setIsOpen) {
        item.isOpen = true;
      }
      return item;
    });
  }

  /**
   * Used to determine which menu item is active
   * @param link
   * @param url
   * @private
   */
  private getActivePrecisionScore(link: string, url: string): number {
    // count matching characters from the start of the link and url
    // link must be equal or shorter than url
    if (!url.includes(link)) {
      return 0;
    }
    let precisionScore = 0;
    for (let i = 0; i < link.length; i++) {
      if (link[i] === url[i]) {
        precisionScore++;
      } else {
        break;
      }
    }
    return precisionScore;
  }


  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e: Event) {
    //const sidebarFolded = this.document.body.classList.contains('sidebar-folded');
    //const sidebarOpen = this.document.body.classList.contains('sidebar-open');
    //const togglerActive = this.sidebarToggler.nativeElement.classList.contains('active');
    //const togglerNotActive = this.sidebarToggler.nativeElement.classList.contains('not-active');
    //console.table({sidebarFolded, sidebarOpen, togglerActive, togglerNotActive});

    if (window.matchMedia('(min-width: 992px)').matches) {
      // collapse and expand sidebar
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');

    } else if (window.matchMedia('(max-width: 991px)').matches) {
      // open and close sidebar

      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
      this.sidebarToggler.nativeElement.classList.toggle('active');
      this.sidebarToggler.nativeElement.classList.toggle('not-active');
    }
  }

  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.add("open-sidebar-folded");
    }
  }


  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.remove("open-sidebar-folded");
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(mq: MediaQueryList) {
    if (mq.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  private flattenMenuItems(items: MenuItem[]) {
    return items.reduce((acc, item) => {
      acc.push(item);
      if (item.subItems) {
        acc.push(...this.flattenMenuItems(item.subItems));
      }
      return acc;
    }, [] as MenuItem[]);
  }
}
